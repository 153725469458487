import type { FC } from "react";
import React, { useMemo } from "react";
import FilePreview from "src/ui/components/FilePreview/FilePreview";
import type { ChatMessage } from "src/ui/components/Chat/ChatBloc";
import translate from "src/lib/translate";

interface SimpleFilePreviewProps {
  name: string;
  type: string;
  id: string;
}

const DisplayFileAttachmentsFileList: FC<{
  message: ChatMessage;
}> = ({ message }) => {
  const fileTypeName = useMemo(() => {
    if (message.computed.isLabReportUpload) {
      return translate("type_name_lab_report_upload");
    }
    return translate("type_name_file_unknown");
  }, [message.computed]);

  const filePreviews = useMemo<SimpleFilePreviewProps[]>(() => {
    const list: SimpleFilePreviewProps[] = [];
    const fileIds = message.computed.filesIdList ?? [];

    for (const fileId of fileIds) {
      let type = "";
      if (message.computed.isLabReportUpload) {
        type = `Page: ${fileIds.indexOf(fileId) + 1} of ${fileIds.length}`;
      }
      list.push({
        name: fileTypeName,
        type,
        id: fileId
      });
    }

    return list;
  }, [message.computed.filesIdList, fileTypeName]);

  return (
    <>
      {filePreviews.map((file) => (
        <FilePreview
          key={file.id}
          id={file.id}
          name={file.name}
          type={file.type}
        />
      ))}

      {message.computed.fileAttachments.map((file) => (
        <FilePreview
          key={file.source}
          id={file.source}
          name={file.name}
          type={file.type}
          size={file.size}
          imageWidth={file.imageWidth}
          imageHeight={file.imageHeight}
        />
      ))}
    </>
  );
};

export default DisplayFileAttachmentsFileList;
