import styled from "@emotion/styled";
import type { FC } from "react";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import AvatarCubit from "src/state/AvatarCubit/AvatarCubit";
import { useBloc } from "src/state/state";
import type {
  ChatMessage,
  KnownSenderType
} from "src/ui/components/Chat/ChatBloc";
import ChatMessageContent from "src/ui/components/Chat/ChatMessageContent";
import LabValueItem from "src/ui/components/LabValueItem/LabValueItem";
import OnEvent from "src/ui/components/OnEvent/OnEvent";
import DisplayFileAttachmentsFileList from "src/ui/components/Chat/DisplayFileAttachmentsFileList";
import { keyframes } from "@emotion/css";
import UserFeedback from "../Feedback/UserFeedback";
import type { Rating } from "../Feedback/Feedback";
import ChatMessageContext from "./ChatMessageContext";
import {
  METADATA_NPS_FEEDBACK_RATING,
  METADATA_NPS_FEEDBACK_RATING_NUMBER,
  METADATA_NPS_FEEDBACK_RATING_TEXT
} from "src/constants/misc";

const ChatMessageItemFrame = styled.div`
  width: 100%;
`;

const blinkingKeyframes = () => keyframes`
  0% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.7;
  }
`;

const ChatMessageRow = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0 auto;
  width: min(100%, 640px);
  padding: 0 1em 1em;

  ul > li {
    margin: 0.2em 0;
  }

  &[data-local="true"] {
    animation: ${blinkingKeyframes} 0.6s ease-in-out infinite alternate;
  }
`;

const ChatMessageItem: FC<{
  message: ChatMessage;
  isLastMessage: boolean;
  markMessageAsRead?: (message: ChatMessage) => void;
}> = ({ message, isLastMessage, markMessageAsRead }) => {
  const [avatar] = useBloc(AvatarCubit, {
    create: () => new AvatarCubit(message.apiItem?.senderId)
  });
  const navigate = useNavigate();

  const {
    attachedLifelineItems,
    sentByUser,
    senderName,
    formattedReceivedDate,
    contentValueGenerated,
    isLocal,
    isNpsFeedback
  } = message.computed;

  const rating: Rating =
    isNpsFeedback && message.apiItem?.metadata?.[METADATA_NPS_FEEDBACK_RATING];

  const hasFileAttachments = message.computed.fileAttachments.length > 0;

  const msgProps: Record<string, boolean | string> = {};

  if (sentByUser || contentValueGenerated) {
    msgProps["hide-avatar"] = true;
  }

  React.useEffect(() => {
    if (isLastMessage && markMessageAsRead && !isLocal) {
      markMessageAsRead(message);
    }
  }, [isLastMessage, markMessageAsRead, message, isLocal]);

  if (contentValueGenerated) {
    // only use message to position the content, remove styling
    msgProps["just-position"] = true;
  }

  if (hasFileAttachments && sentByUser) {
    // hide frame for attachments sent by user
    msgProps["just-position"] = true;
  }

  const avatarFallback =
    message.senderType === "STAFF_PHYSICIAN" ? "/9amAvatar.png" : undefined;
  const avatarUrl = avatar.path ?? avatarFallback;

  const handleOpenDetails = (): void => {
    const bioPopupRoles: KnownSenderType[] = [
      "STAFF_CUSTOMER_SUPPORT",
      "STAFF_DIABETES_EDUCATOR",
      "STAFF_PHYSICIAN",
      "STAFF"
    ];
    const show = bioPopupRoles.includes(
      message.apiItem?.senderMetadata?.role ?? ""
    );
    if (show) navigate(`?popup=teamDetails&id=${message.apiItem?.senderId}`);
  };

  const dayTimeString = useMemo(() => {
    const localStatus = isLocal ? "sending..." : "";

    if (localStatus) {
      return localStatus;
    }
    return formattedReceivedDate;
  }, [isLocal, formattedReceivedDate]);

  return (
    <ChatMessageRow data-local={isLocal}>
      <ChatMessageItemFrame>
        <OnEvent
          events={{
            "nine:openDetails": handleOpenDetails
          }}
        >
          <ChatMessageContext.Provider value={{ id: message.id }}>
            <nine-message
              daytime={dayTimeString}
              author={senderName}
              credentials={message.computed.senderDescription}
              profile-image={avatarUrl}
              message-type={
                sentByUser || contentValueGenerated ? "user" : "staff"
              }
              style={{
                "--msg-max-w": "85%",
                "--msg-w": contentValueGenerated ? "100%" : "auto"
              }}
              {...msgProps}
            >
              <ChatMessageContent message={message} />

              <DisplayFileAttachmentsFileList message={message} />

              {isNpsFeedback && (
                <UserFeedback
                  rating={rating}
                  ratingGrade={
                    message.apiItem?.metadata?.[
                      METADATA_NPS_FEEDBACK_RATING_NUMBER
                    ]
                  }
                  userFeedback={
                    message.apiItem?.metadata?.[
                      METADATA_NPS_FEEDBACK_RATING_TEXT
                    ]
                  }
                />
              )}
              {formattedReceivedDate &&
                attachedLifelineItems.map((id) => (
                  <LabValueItem id={id} key={id} />
                ))}
            </nine-message>
          </ChatMessageContext.Provider>
        </OnEvent>
      </ChatMessageItemFrame>
    </ChatMessageRow>
  );
};

export default ChatMessageItem;
