import styled from "@emotion/styled";
import type { FC } from "react";
import React, { useMemo } from "react";
import HtmlParser from "src/lib/HtmlParser";
import type { ChatMessage } from "src/ui/components/Chat/ChatBloc";

const ChatMessageContentStyles = styled.div`
  display: contents;
  a {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
`;

const ChatMessageContent: FC<{ message: ChatMessage }> = ({ message }) => {
  const contentParsed = useMemo(() => {
    if (
      message.computed.showContentValue &&
      message.type === "text/html" &&
      message.text
    ) {
      return new HtmlParser(message.text).toJsx();
    }
    return <></>;
  }, [message]);

  if (message.computed.showContentValue === false) {
    return <></>;
  }

  switch (message.type) {
    case "text/plain":
      return (
        <ChatMessageContentStyles className="as-body">
          {message.text}
        </ChatMessageContentStyles>
      );
    case "text/html":
      return (
        <ChatMessageContentStyles className="as-body">
          {contentParsed}
        </ChatMessageContentStyles>
      );
    default:
      return <ChatMessageContentStyles></ChatMessageContentStyles>;
  }
};

export default ChatMessageContent;
