import type { FC } from "react";
import React, { useCallback, useEffect, useState } from "react";
import { LoincCodingCode } from "src/constants/fhir";
import Fhir from "src/lib/fhir";
import { getSupportedUserLocale } from "src/lib/i18next";
import translate from "src/lib/translate";
import LabResultsCubit from "src/state/LabResultsCubit/LabResultsCubit";

const LabValueItem: FC<{
  id: string;
}> = (props) => {
  const [loading, setLoading] = useState(true);
  const [displayData, setDisplayData] = useState<{
    code: string;
    title: string;
    value: string;
    date: string;
    unit: string;
    error?: string;
  } | null>(null);

  const loadLabData = useCallback(async (id: string) => {
    setLoading(true);
    const labValue =
      await LabResultsCubit.getObservationFromLifelineItemById(id);
    const values = Fhir.autoParseObservation(labValue);

    if (values) {
      const localParsedValue = Fhir.localObservationData(values);

      setDisplayData({
        code: localParsedValue.code,
        title: localParsedValue.title,
        value: localParsedValue.value,
        date: localParsedValue.date
          .toDate()
          .toLocaleString(getSupportedUserLocale(), {
            month: "short",
            day: "numeric",
            hour: localParsedValue.hasTime ? "numeric" : undefined,
            minute: localParsedValue.hasTime ? "numeric" : undefined,
            hour12: true
          }),
        unit: localParsedValue.unit,
        error: localParsedValue.errors?.join(", ")
      });
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    void loadLabData(props.id);
  }, [props.id]);

  if (!displayData) {
    return null;
  }

  return (
    <nine-lab-value
      {...{
        loading: loading ? true : undefined
      }}
    >
      {(displayData.code === LoincCodingCode.bloodGlucoseCapillary ||
        displayData.code === LoincCodingCode.bloodGlucoseCapillaryFasting) && (
        <svg
          width="16"
          {...{
            slot: "icon"
          }}
          height="16"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.1998 14.6388C19.1998 17.5101 16.9252 19.8035 14.1612 19.8035C12.4191 19.8035 11.3033 19.3166 10.3971 17.924L9.4998 18.4999C10.5591 20.2339 12.0123 20.7635 14.1612 20.7635C17.4741 20.7635 20.1598 18.0214 20.1598 14.6388C20.1598 10.1118 16.2316 5.20574 14.7328 3.49867C14.4257 3.14888 13.8967 3.14888 13.5896 3.49867C13.5752 3.51508 13.5608 3.53152 13.5463 3.54799C12.8566 4.33324 12.0903 5.20586 10.9998 6.99994L11.8747 7.5131C12.8587 5.90978 13.477 5.09682 14.1612 4.30414C14.9097 5.17128 16.1259 6.68853 17.179 8.49477C18.3169 10.4466 19.1998 12.6275 19.1998 14.6388ZM8.31433 8.10162C8.42258 8.03909 8.55613 8.03909 8.66438 8.10162L11.8066 9.91687L8.48522 11.8404L5.17258 9.91661L8.31433 8.10162ZM8.95904 12.6754L12.2179 10.788V14.2627C12.2179 14.3864 12.1519 14.5011 12.044 14.5635L8.95904 16.3456V12.6754ZM4.9347 14.5635L7.99904 16.3337V12.6728L7.99906 12.6682L4.76078 10.7876V14.2627C4.76078 14.3864 4.82684 14.5011 4.9347 14.5635ZM9.14459 7.27037C8.73922 7.03618 8.23949 7.03618 7.83412 7.27037L4.45448 9.22278C4.05014 9.45636 3.80078 9.8877 3.80078 10.3548V14.2627C3.80078 14.7298 4.05014 15.1611 4.45448 15.3947L7.83412 17.3471C8.23949 17.5813 8.73922 17.5813 9.14459 17.3471L12.5242 15.3947C12.9286 15.1611 13.1779 14.7298 13.1779 14.2627V10.3548C13.1779 9.8877 12.9286 9.45636 12.5242 9.22278L9.14459 7.27037Z"
            fill="#212121"
          />
        </svg>
      )}

      {displayData.code === LoincCodingCode.bloodPressure && (
        <svg
          {...{
            slot: "icon"
          }}
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            fill="#212121"
            fillRule="evenodd"
            d="M3.132 7.545c0-.971.324-1.902.86-2.583.533-.68 1.262-1.096 2.085-1.096 1.137 0 2.02.741 2.592 1.907l.33.67.329-.67c.572-1.166 1.455-1.907 2.592-1.907.823 0 1.552.417 2.085 1.096.536.681.86 1.612.86 2.583 0 .145-.007.289-.02.431.239.186.456.4.645.637.07-.346.108-.702.108-1.068 0-1.127-.374-2.22-1.016-3.036-.644-.82-1.57-1.376-2.662-1.376-1.284 0-2.261.72-2.922 1.736-.66-1.016-1.637-1.736-2.921-1.736-1.091 0-2.018.556-2.662 1.376-.642.817-1.017 1.909-1.017 3.036 0 2.509 1.663 4.524 3.245 5.88a16.81 16.81 0 0 0 3.175 2.128l.015.007.004.002.002.001.165.08.162-.084h.002l.004-.003.014-.008a5.854 5.854 0 0 0 .26-.142 18.628 18.628 0 0 0 1.418-.895 3.85 3.85 0 0 1-.692-.415 17.965 17.965 0 0 1-1.178.723l-.09-.048a16.05 16.05 0 0 1-2.783-1.903c-1.536-1.316-2.99-3.144-2.99-5.323Zm9.533 4.388a.733.733 0 1 0-.248-1.424l-.843-.843-.518.519.887.887a.736.736 0 0 0 .722.86Zm2.933-.917a3.117 3.117 0 1 1-6.233 0 3.117 3.117 0 0 1 6.233 0Zm-.733 0a2.383 2.383 0 1 1-4.767 0 2.383 2.383 0 0 1 4.767 0Z"
            clipRule="evenodd"
          />
        </svg>
      )}

      {displayData.code === LoincCodingCode.weight && (
        <svg
          {...{
            slot: "icon"
          }}
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fill="#212121"
            fillRule="evenodd"
            d="M17.926 8.125c.37.41.257 1.038-.195 1.355l-2.436 1.71c-.453.316-1.07.188-1.517-.136a3.01 3.01 0 0 0-1.728-.578 3.025 3.025 0 0 0-1.746.52c-.457.31-1.079.417-1.52.085L6.406 9.29c-.441-.331-.534-.963-.15-1.36a8 8 0 0 1 11.67.194Zm-.828.578a7 7 0 0 0-10.033-.167l2.318 1.744a.177.177 0 0 0 .066.003.665.665 0 0 0 .295-.115 4.02 4.02 0 0 1 2.4-.69.503.503 0 0 1 .074-.113l1.373-1.579a.5.5 0 0 1 .755.656l-1.078 1.239a4.024 4.024 0 0 1 1.098.564.662.662 0 0 0 .29.125.176.176 0 0 0 .066-.001l2.376-1.666Zm-2.372 1.664-.004.002.004-.002Zm-5.347-.089.004.002-.004-.002Z"
            clipRule="evenodd"
          />
          <path
            fill="#212121"
            fillRule="evenodd"
            d="M18.108 21a2 2 0 0 0 1.997-1.889l.778-14A2 2 0 0 0 18.886 3H5.114a2 2 0 0 0-1.997 2.111l.778 14A2 2 0 0 0 5.892 21h12.216ZM5.892 20h12.216a1 1 0 0 0 .998-.945l.778-14A1 1 0 0 0 18.886 4H5.114a1 1 0 0 0-.998 1.055l.778 14a1 1 0 0 0 .998.945Z"
            clipRule="evenodd"
          />
        </svg>
      )}

      {displayData.code === LoincCodingCode.waistCircumference && (
        <svg
          {...{
            slot: "icon"
          }}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.01237 7.40691C1.45002 6.84455 1.45002 5.93279 2.01237 5.37044L5.33241 2.05041C5.89476 1.48805 6.80652 1.48805 7.36887 2.05041L21.9292 16.6107C22.4916 17.1731 22.4916 18.0849 21.9292 18.6472L18.6092 21.9672C18.0468 22.5296 17.1351 22.5296 16.5727 21.9672L2.01237 7.40691ZM2.6912 6.04926C2.50375 6.23671 2.50375 6.54063 2.6912 6.72808L3.8367 7.87358L6.01123 5.69905C6.19868 5.5116 6.5026 5.5116 6.69005 5.69905C6.8775 5.8865 6.8775 6.19042 6.69005 6.37788L4.51552 8.55241L5.52825 9.56513L6.77789 8.31549C6.96534 8.12804 7.26926 8.12804 7.45671 8.31549C7.64416 8.50294 7.64416 8.80686 7.45671 8.99431L6.20707 10.244L7.21979 11.2567L9.39432 9.08215C9.58177 8.8947 9.88569 8.8947 10.0731 9.08215C10.2606 9.2696 10.2606 9.57352 10.0731 9.76097L7.89861 11.9355L8.90884 12.9457L10.1585 11.6961C10.3459 11.5086 10.6498 11.5086 10.8373 11.6961C11.0248 11.8835 11.0248 12.1875 10.8373 12.3749L9.58766 13.6245L10.5979 14.6348L12.7724 12.4602C12.9599 12.2728 13.2638 12.2728 13.4512 12.4602C13.6387 12.6477 13.6387 12.9516 13.4512 13.1391L11.2767 15.3136L12.2855 16.3224L13.5351 15.0727C13.7226 14.8853 14.0265 14.8853 14.2139 15.0727C14.4014 15.2602 14.4014 15.5641 14.2139 15.7515L12.9643 17.0012L13.9731 18.01L16.1476 15.8354C16.335 15.648 16.639 15.648 16.8264 15.8354C17.0139 16.0229 17.0139 16.3268 16.8264 16.5142L14.6519 18.6888L15.6428 19.6797L16.8925 18.4301C17.0799 18.2426 17.3838 18.2426 17.5713 18.4301C17.7587 18.6175 17.7587 18.9214 17.5713 19.1089L16.3216 20.3585L17.2515 21.2884C17.439 21.4759 17.7429 21.4759 17.9304 21.2884L21.2504 17.9684C21.4378 17.7809 21.4378 17.477 21.2504 17.2896L6.69005 2.72923C6.5026 2.54178 6.19868 2.54178 6.01123 2.72923L2.6912 6.04926Z"
            fill="#212121"
          />
        </svg>
      )}

      <span slot="title">
        {displayData.error ? (
          <>
            <span>{displayData.error}</span>
          </>
        ) : (
          <>
            {displayData.value} {displayData.unit} ({displayData.date})
          </>
        )}
      </span>
      <span slot="text">
        {displayData.title} {translate("added")}
      </span>
    </nine-lab-value>
  );
};

export default LabValueItem;
